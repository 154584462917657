<template>

  <div v-if="data.form && data.form.fields && data.form.fields.length" class="flex flex-col px-4 w-full xitems-center">

    <div v-for="(field, index) in data.form.fields" :key="index" class="flex flex-col w-full sm:w-2/3 xs:w-1/3 text-left">


        <!-- HEADER -->
        <div v-if="field.type === 'header'">
          <h1 class="mt-2 mb-4 pb-2 font-normal">{{ field.label }}</h1>
        </div>


        <!-- LABEL -->
        <div v-if="field.type === 'label'">
          <label class="mt-2 mb-4 pb-2 font-normal">{{ field.label }}</label>
        </div>


        <!-- DATEPICKER -->
        <div v-else-if="field.type === 'datepicker'" class="pb-8">
          <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
          <input
            :id="'formfield-' + field.formfieldid"
            type="date"
            v-model="field.value"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
          />
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- DATETIMEPICKER -->
        <div v-else-if="field.type === 'datetimepicker'" class="pb-8">
          <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
          <input
            :id="'formfield-' + field.formfieldid"
            type="datetime-local"
            v-model="field.value"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
          />
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- NUMERIC -->
        <div v-else-if="field.type === 'numeric'" class="pb-8">
          <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
          <input
            :id="'formfield-' + field.formfieldid"
            type="number"
            v-model="field.value"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
          />
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- TEXT -->
        <div v-else-if="field.type === 'text'" class="pb-8">
          <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
          <input
            :id="'formfield-' + field.formfieldid"
            type="text"
            v-model="field.value"
            :required="field.required"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
          />
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- URL -->
        <div v-else-if="field.type === 'url'" class="pb-8">
          <div class="block">
            <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
            <input
              :id="'formfield-' + field.formfieldid"
              type="url"
              v-model="field.value"
              :required="field.required"
              @blur="validateField(field)"
              :class="{ 'border-red-500': field.error }"
              class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
            />
          </div>
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- TEXTAREA -->
        <div v-else-if="field.type === 'textarea'" class="pb-8">
          <label-field class="block mb-2" :for="'formfield-' + field.formfieldid" :required="field.required || false">{{ field.label }}</label-field>
          <textarea
            :id="'formfield-' + field.formfieldid"
            v-model="field.value"
            :required="field.required"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm p-2 mt-1 font-hairline leading-loose"
          ></textarea>
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- CHECKBOX -->
        <div v-else-if="field.type === 'checkbox'" class="pb-8">
          <div class="flex items-center">
            <input
              :id="'formfield-' + field.formfieldid"
              type="checkbox"
              v-model="field.value"
              :required="field.required"
              @blur="validateField(field)"
              :class="{ 'border-red-500': field.error }"
              class="mr-2"
              style="margin-bottom:2px;"
            />
            <label-field
              :for="'formfield-' + field.formfieldid"
              :required="field.required || false"
            >{{ field.label }}</label-field>
          </div>
          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
        </div>


        <!-- YESNO -->
        <div v-else-if="field.type === 'yesno'" class="pb-8">
          <label-field class="block mb-2" :required="field.required || false">{{ field.label }}</label-field>
          <div class="pl-4">
            <div class="flex">
              <label class="mr-4">
                <input
                  type="radio"
                  v-model="field.value"
                  @blur="validateField(field)"
                  value="yes"
                  class="shadow-inner border text-sm font-normal"
                /> Yes
              </label>
              <label>
                <input
                  type="radio"
                  v-model="field.value"
                  @blur="validateField(field)"
                  value="no"
                  class="shadow-inner border text-sm font-normal"
                /> No
              </label>
            </div>
            <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>
          </div>
        </div>


        <!-- LOGIC_BUTTON -->
        <div v-else-if="field.type === 'logic_button'" class="pb-8">

          <AclButton :field="field">{{ field.label }}</AclButton>

          <div v-if="field.message" class="font-sans font-hairline text-xs mt-2">{{ field.message }}</div>

          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-2">{{ field.error }}</div>

        </div>


        <!-- LOGIC_COMBOSINGLE -->
        <div v-else-if="field.type === 'logic_combosingle'" class="pb-8">

          <label-field class="block mb-2" :required="field.required || false">{{ field.label }}</label-field>

          <select-field
            :field="field"
            :internalSearch="true"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm font-hairline leading-loose"
          ></select-field>

          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>

        </div>


        <!-- COMBOSINGLE -->
        <div v-else-if="field.type === 'combosingle'" class="pb-8">

          <label-field class="block mb-2" :required="field.required || false">{{ field.label }}</label-field>

          <FactorDropdown
            :field="field"
            :multiple="false"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm font-hairline leading-loose"
          ></FactorDropdown>

          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>

        </div>


        <!-- COMBOMULTI -->
        <div v-else-if="field.type === 'combomulti'" class="pb-8">

          <label-field class="block mb-2" :required="field.required || false">{{ field.label }}</label-field>

          <FactorDropdown
            :field="field"
            :multiple="true"
            @blur="validateField(field)"
            :class="{ 'border-red-500': field.error }"
            class="w-full block shadow-inner border text-sm font-hairline leading-loose"
          ></FactorDropdown>

          <div v-if="field.error" class="font-sans font-hairline text-xs text-red-700 mt-1">{{ field.error }}</div>

        </div>

    </div>

    <!-- <Recaptcha :data="data"></Recaptcha> -->
    <Recaptcha3 :data="data"></Recaptcha3>

  </div>

</template>

<script>

import LabelField from '@/components/form/LabelField.vue'

import SelectField from '@/components/form/SelectField.vue'
import FactorDropdown from '@/components/form/FactorDropdown.vue'

// import SearchDropdown from '@/components/form/SearchDropdown.vue'

import AclButton from '@/components/form/AclButton.vue'

// import Recaptcha from '@/components/form/Recaptcha.vue'

import Recaptcha3 from '@/components/form/Recaptcha3.vue'

export default {

  name: 'VaForm',

  components: {
    LabelField,
    SelectField,
    FactorDropdown,
    // SearchDropdown,
    AclButton,
    // Recaptcha,
    Recaptcha3,
  },

  props: {

    data: { type: Object }

  },

  methods: {

    validateField (field) {

      field.error = false;

    }

  }

}
</script>
