<template>

  <multiselect
    :multiple="multiple"
    v-model="field.selected"
    :value="field.value"
    :internal-search="true"
    @blur.native="$emit('blur')"
    @open="onOpen"
    @close="onClose"
    @input="onInput"
    :deselect-label="field.deselectLabel || ''"
    track-by="value"
    label="text"
    :placeholder="field.placeholder || ''"
    :options="field.data || []"
    :searchable="field.searchable || false"
    :allowEmpty="field.allowEmpty || false"
    :disabled="field.disabled || false"
    :select-label="field.selectLabel || ''"
    :selected-label="field.selectedLabel || ''"
    :loading="loading"
  >

    <template slot="noResult">
      <div>No results found</div>
    </template>

    <template slot="option" slot-scope="{ option }">

      <div>{{ option.text }}</div>

    </template>

    <template slot="afterList"></template>

  </multiselect>

</template>

<script>

import Multiselect from '@/components/vue-multiselect/Multiselect'

export default {

  name: 'SelectField',

  components: {
    Multiselect
  },

  props: {
    field: { type: Object },
    multiple: { type: Boolean, default: false }
  },

  data() {
    return {
      loading: false,
      loaded: false,
      opened: false
    }
  },

  methods: {

    onOpen() {
      this.opened = true;
    },

    onClose() {
      this.opened = false;
    },

    onInput(value) {
      value ? this.field.value = value.value : false;
    }

  }

}
</script>
